.author {
  margin-left: 10px;
  font-weight: bold;
}

.item {
  border: none !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.lemon {
  margin-right: 10px;
}

.spanDate {
  font-weight: bold;
}

.link {
  color: #12c7b1;
  margin-left: 10px;
}
