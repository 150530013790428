.item {
  border: none !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.lemon {
  margin-right: 10px;
}

.spanAuthor {
  font-weight: bold;
  margin-left: 10px;
}

.link {
  color: #12c7b1;
}
