.title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16 / 9;
}

/* Style the iframe to fit in the container div with full height and width */
.responsiveframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
