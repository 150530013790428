.title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.membersContainer {
  display: grid;
  justify-content: center;
  gap: 16px 32px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(4, 7rem);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(6, 7rem);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(8, 7rem);
  }
}

.memberItem {
  max-width: 22rem;
  grid-column: span 2;
}

@media (min-width: 576px) and (max-width: 768px) {
  .memberItem:last-child:nth-child(2n - 1) {
    grid-column-end: -2;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .memberItem:last-child:nth-child(2n - 1) {
    grid-column-end: 4;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .memberItem:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }
  .memberItem:nth-last-child(1):nth-child(3n + 1) {
    grid-column-end: 5;
  }
}

@media (min-width: 1200px) {
  .memberItem:nth-last-child(3):nth-child(4n + 1) {
    grid-column-end: 4;
  }
  .memberItem:nth-last-child(2):nth-child(4n + 1) {
    grid-column-end: 5;
  }
  .memberItem:nth-last-child(1):nth-child(4n + 1) {
    grid-column-end: 6;
  }
}
