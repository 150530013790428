.item {
  border: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  max-width: 32px !important;
}

.rocket {
  color: #12c7b1;
}
